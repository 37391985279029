import { OrganisationResponse } from '@joc/api-gateway';

export const getListVolunteers = (arr: Array<OrganisationResponse>, defaultOrganizationId?: string) => {
	const defaultOrg = arr.find(org => org.id === defaultOrganizationId);
	const initArr: Array<string> = [...(defaultOrg ? [defaultOrg.organizationName] : [])];

	const resultList = arr
		.filter(org => (defaultOrg ? defaultOrg.id !== org.id : org))
		.reduce((accumulator, currentValue) => [...accumulator, currentValue.organizationName], initArr);
	return resultList.join(', ');
};
