import { FC } from 'react';

// components
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import InputSearch from 'shared/components/Inputs/InputSearch';

// styles
import styles from './StoreItemsTableActions.module.scss';

type StoreItemsActionsProps = {
	onCreateStoreItem?: () => void;
	// eslint-disable-next-line no-unused-vars
	onSearchStoreItem: (search: string) => void;
};

const StoreItemsActions: FC<StoreItemsActionsProps> = ({ onCreateStoreItem, onSearchStoreItem }) => {
	const createStoreItemHandler = () => onCreateStoreItem?.();

	return (
		<section className={styles.tableActions}>
			<InputSearch placeholder="Search..." changeHandler={onSearchStoreItem} />

			<ButtonDefault
				title="+ Create"
				classList={['primary', 'sm']}
				parentClassName={styles.tableActions__button}
				clickHandler={createStoreItemHandler}
			/>
		</section>
	);
};

export default StoreItemsActions;
