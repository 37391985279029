import { FC, useState, Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//redux
import { resetError } from 'redux/error-service/action';
import { selectorGetError } from 'redux/error-service/selector';
//components
import WhiteContainer from 'shared/components/WhiteContainer';
import VolunteerInfoForm from 'components/Organization/EditForms/VolunteerInfoForm';
import ResponseFailure from 'shared/components/ResponseFailure';
import CongratsPopup from 'components/Organization/CongratsPopup';
//styles
import styles from './EditVolunteerAccount.module.scss';
import PopupContainer from '../../../../../shared/components/PopupContainer';

interface IEditVolunteerAccountProps {
	setIsShowPopup: Dispatch<SetStateAction<boolean>>;
}

const EditVolunteerAccount: FC<IEditVolunteerAccountProps> = ({ setIsShowPopup }) => {
	const dispatch = useDispatch();

	const error = useSelector(selectorGetError);

	const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);

	const congratsDoneButtonClickHandler = () => {
		setIsUpdateSuccess(false);
		setIsShowPopup(false);
	};

	const handleRetryClickHandler = () => dispatch(resetError);

	return (
		<div className={styles.container}>
			{error.state ? (
				<ResponseFailure
					message={error.message}
					buttonClickHandler={handleRetryClickHandler}
					buttonTitle="Check and try again"
					styleTable
				/>
			) : (
				<WhiteContainer title="Volunteer info" parentClassName={styles.white__container}>
					<VolunteerInfoForm setIsUpdateSuccess={setIsUpdateSuccess} />
				</WhiteContainer>
			)}

			{isUpdateSuccess ? (
				<PopupContainer
					setIsShowPopup={setIsShowPopup}
					isFixedStyle={true}
					parentClassName={styles.success__popup}
				>
					<CongratsPopup
						subtitle="Volunteer's account have been updated successfully"
						doneButtonClickHandler={congratsDoneButtonClickHandler}
						title="Success"
						isEditVolunteer={true}
						isFullHeight={false}
					/>
				</PopupContainer>
			) : null}
		</div>
	);
};

export default EditVolunteerAccount;
