import React, { FC, useState } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';

// components
import StoreItemPopup from '../../../StorePopups/StoreItemPopup';
import StoreCouponsPopup from '../../../StorePopups/StoreCouponsPopup';

// icons
import { ReactComponent as DeleteSvg } from 'assets/image/icons/trash-new.svg';
import { ReactComponent as ThreeDotsSvg } from 'assets/image/icons/menu.svg';
import { ReactComponent as EditSvg } from 'assets/image/icons/edit-new.svg';
import { ReactComponent as CouponSvg } from 'assets/image/icons/coupone.svg';

// types
import { IStoreItemResponse } from '@joc/api-gateway';
import { StoreItemResponseType } from '../../../types';

// hooks
import { useDeleteStoreItem } from 'hooks/store/items/useDeleteStoreItem';

// styles
import styles from './StoreTableActions.module.scss';

type StoreItemActionsTypes = {
	storeItem: IStoreItemResponse;
};

const StoreItemActions: FC<StoreItemActionsTypes> = ({ storeItem }) => {
	const [openItemId, setOpenItemId] = useState<number>(0);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [popupEditOpen, setPopupEditOpen] = useState(false);
	const [popupManageCouponsOpen, setPopupManageCouponsOpen] = useState(false);
	const { deleteStoreItem } = useDeleteStoreItem();

	const togglePopupHandler = () => {
		handleClose();
		setPopupEditOpen(!popupEditOpen);
	};

	const togglePopupManageCouponsHandler = () => {
		handleClose();
		setPopupManageCouponsOpen(!popupManageCouponsOpen);
	};

	const handleDeleteStoreItem = () => deleteStoreItem(storeItem.id);

	const handleClose = () => {
		setAnchorEl(null);
		setOpenItemId(0);
	};

	const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
		setOpenItemId(storeItem.id);
	};

	return (
		<>
			<IconButton
				aria-label="actions"
				aria-controls={openItemId === storeItem.id ? 'long-menu' : undefined}
				onClick={handleOpen}
			>
				<ThreeDotsSvg className={openItemId === storeItem.id ? styles.active : ''} />
			</IconButton>
			<Menu
				open={openItemId === storeItem.id}
				anchorEl={anchorEl}
				onClose={handleClose}
				MenuListProps={{
					className: styles.actionsList,
				}}
				PaperProps={{
					className: styles.paper,
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 12,
					horizontal: 206,
				}}
				getContentAnchorEl={null}
			>
				<MenuItem className={styles.menuItem} onClick={togglePopupHandler}>
					<div className={styles.actionsContainer}>
						<EditSvg title="Edit" />
						<span className={styles.actionsText}>Edit</span>
					</div>
				</MenuItem>
				<MenuItem className={styles.menuItem} onClick={handleDeleteStoreItem}>
					<div className={styles.actionsContainer}>
						<DeleteSvg title="Delete" />
						<span className={styles.actionsText}>Delete</span>
					</div>
				</MenuItem>
				<MenuItem className={styles.menuItem} onClick={togglePopupManageCouponsHandler}>
					<div className={styles.actionsContainer}>
						<CouponSvg title="Manage coupons" />
						<span className={styles.actionsText}>Manage coupons</span>
					</div>
				</MenuItem>
			</Menu>
			{popupEditOpen ? (
				<StoreItemPopup
					open={popupEditOpen}
					storeItem={storeItem as unknown as StoreItemResponseType}
					onClose={togglePopupHandler}
				/>
			) : null}
			{popupManageCouponsOpen ? (
				<StoreCouponsPopup
					open={popupManageCouponsOpen}
					storeItem={storeItem}
					onClose={togglePopupManageCouponsHandler}
				/>
			) : null}
		</>
	);
};

export default StoreItemActions;
