import { createContext, FC, useContext, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import qs from 'qs';
// redux
import { Store } from 'redux/root';
import { useSelector } from 'react-redux';
import { resetError } from 'redux/error-service/action';
// constants
import { INITIAL_PAGINATION, TABLE_HEADERS, TABLE_ITEMS_CLASSNAMES } from 'core/constants';
// functions
import {
	calculateUserAge,
	convertTimeFromSecondsToHours,
	generateGenderName,
	generateLocation,
	setFullName,
	setSearchOrSortQueryString,
} from 'core/functions';
// types
import {
	IBasicVolunteerResponse,
	IFullTextSearchVolunteers,
	ISearchVolunteersSort,
	SEARCH_VOLUNTEER_POSSIBLE_SEARCH,
	SEARCH_VOLUNTEER_POSSIBLE_SORT,
	SortDirection,
	VOLUNTEER_ACTIVE_STATUS,
} from '@joc/api-gateway';
// context
import { VolunteersPageContext, VolunteersPageContextType } from 'pages/Organization/Volunteers';
// components
import ContactCell from 'shared/components/Table/CellRenderers/ContactCell';
import PastOpportunitiesCell from 'shared/components/Table/CellRenderers/PastOpportunitiesCell';
import Loader from 'shared/components/Loader';
import ResponseFailure from 'shared/components/ResponseFailure';
import TableMenuWithFilter from 'components/Organization/TableMenuWithFilter';
import AvatarCell from 'shared/components/Table/CellRenderers/AvatarCell';
import FullNameCell from 'shared/components/Table/CellRenderers/FullNameCell';
import CellDefault from 'shared/components/Table/CellRenderers/CellDefault';
import Observer from 'shared/components/Observer';
import TableHead from 'shared/components/Table/TableHead';
import TableHeadCell from 'shared/components/Table/TableHead/TableHeadCell';
import TableRow from 'shared/components/Table/TableRow';
import TableMain from 'shared/components/Table/TableMain';
import TableBody from 'shared/components/Table/TableBody';
import VolunteerActionCell from './VolonteerActionCell';
import Actions from './Actions';
// styles
import styles from './index.module.scss';
import { getListVolunteers } from './helper';

export const VolunteerTableDataContext = createContext({} as IBasicVolunteerResponse);

const VolunteersTable: FC = () => {
	const [isPrintableTable] = useState(false);

	const printRef = useRef<HTMLDivElement>(null);

	const history = useHistory();

	const location = useLocation();

	const volunteersRecordsList = useSelector((store: Store) => store.volunteersRedux.volunteers.records);
	const volunteersRecordsTotal = useSelector((store: Store) => store.volunteersRedux.volunteers.total);
	const errorState = useSelector((store: Store) => store.errorRedux.error.state);
	const errorMessage = useSelector((store: Store) => store.errorRedux.error.message);

	const {
		isLoading,
		sort,
		setPagination,
		changeVisibilityFilters,
		isClearSearch,
		setIsClearSearch,
	}: VolunteersPageContextType = useContext(VolunteersPageContext);

	const buttonFailureClickHandler = () => {
		resetError();
		history.push({ search: '' });
		setPagination(INITIAL_PAGINATION);
	};

	const appendQueryString = (
		newQueryParams: IFullTextSearchVolunteers | ISearchVolunteersSort,
		searchBy: string
	): void => {
		const searchQuery = setSearchOrSortQueryString(location.search, newQueryParams, searchBy);
		history.push({ search: searchQuery });
	};

	const searchVolunteersChangeHandler = (value: string): void => {
		const fullTextSearchParams: IFullTextSearchVolunteers = {
			value,
			fields: Object.values(SEARCH_VOLUNTEER_POSSIBLE_SEARCH),
		};
		if (fullTextSearchParams.value.length) {
			appendQueryString(fullTextSearchParams, 'fullTextSearch');
		} else {
			const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true });
			delete searchParams.fullTextSearch;
			history.push({ search: qs.stringify(searchParams) });
		}
	};

	return (
		<div className={styles.container}>
			<div className={styles.table}>
				<TableMenuWithFilter
					searchChangeHandler={searchVolunteersChangeHandler}
					changeVisibilityFilters={changeVisibilityFilters}
					isClearSearch={isClearSearch}
					setIsClearSearch={setIsClearSearch}
				>
					<Actions />
				</TableMenuWithFilter>
				{(errorState && !isLoading) || (!isLoading && !volunteersRecordsList.length) ? (
					<ResponseFailure
						styleTable
						message={errorMessage || 'We couldn`t find the volunteers you were looking for this time.'}
						buttonClickHandler={buttonFailureClickHandler}
						buttonTitle="Get all"
					/>
				) : !volunteersRecordsList.length && isLoading ? (
					<div className={styles.loader}>
						<Loader />
					</div>
				) : (
					<TableMain ref={printRef}>
						<TableHead callChild="volunteers">
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.avatar}
								text={TABLE_HEADERS.avatar}
								isSortDisabled
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.fullName}
								text={TABLE_HEADERS.fullName}
								clickHandler={() => {
									const newQueryParams: ISearchVolunteersSort = {
										sortBy: SEARCH_VOLUNTEER_POSSIBLE_SORT.FirstName,
										sortDir:
											sort?.sortDir === SortDirection.ASC
												? SortDirection.DESC
												: SortDirection.ASC,
									};
									appendQueryString(newQueryParams, 'sort');
								}}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.email}
								text={TABLE_HEADERS.email}
								clickHandler={() => {
									const newQueryParams: ISearchVolunteersSort = {
										sortBy: SEARCH_VOLUNTEER_POSSIBLE_SORT.Email,
										sortDir:
											sort?.sortDir === SortDirection.ASC
												? SortDirection.DESC
												: SortDirection.ASC,
									};
									appendQueryString(newQueryParams, 'sort');
								}}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.phone}
								text={TABLE_HEADERS.phoneNumber}
								clickHandler={() => {
									const newQueryParams: ISearchVolunteersSort = {
										sortBy: SEARCH_VOLUNTEER_POSSIBLE_SORT.PhoneNumber,
										sortDir:
											sort?.sortDir === SortDirection.ASC
												? SortDirection.DESC
												: SortDirection.ASC,
									};
									appendQueryString(newQueryParams, 'sort');
								}}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.age}
								text={TABLE_HEADERS.age}
								clickHandler={() => {
									const newQueryParams: ISearchVolunteersSort = {
										sortBy: SEARCH_VOLUNTEER_POSSIBLE_SORT.BirthDate,
										sortDir:
											sort?.sortDir === SortDirection.ASC
												? SortDirection.DESC
												: SortDirection.ASC,
									};
									appendQueryString(newQueryParams, 'sort');
								}}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.gender}
								text={TABLE_HEADERS.gender}
								clickHandler={() => {
									const newQueryParams: ISearchVolunteersSort = {
										sortBy: SEARCH_VOLUNTEER_POSSIBLE_SORT.Gender,
										sortDir:
											sort?.sortDir === SortDirection.ASC
												? SortDirection.DESC
												: SortDirection.ASC,
									};
									appendQueryString(newQueryParams, 'sort');
								}}
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.location}
								text={TABLE_HEADERS.location}
								isSortDisabled
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.listOrganizations}
								text={TABLE_HEADERS.listOrganizations}
								isSortDisabled
							/>
							<TableHeadCell
								itemClassName={TABLE_ITEMS_CLASSNAMES.totalTimeSpent}
								text={TABLE_HEADERS.totalTimeSpent}
								isSortDisabled
							/>
							<TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.options} isSortDisabled />
							{!isPrintableTable && <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.options} />}
						</TableHead>
						<TableBody>
							{volunteersRecordsList.map((volunteer: IBasicVolunteerResponse) => (
								<TableRow
									key={volunteer.id}
									callChild="volunteers"
									suspend={
										volunteer.volunteerActiveStatus.status === VOLUNTEER_ACTIVE_STATUS.SUSPENDED
									}
								>
									<AvatarCell imagePath={volunteer.imagePath} user={volunteer} withPopup={true} />
									<FullNameCell
										firstName={volunteer.firstName}
										lastName={volunteer.lastName}
										createDate={volunteer.createDate}
									/>
									<CellDefault
										parentClassName={TABLE_ITEMS_CLASSNAMES.email}
										text={volunteer.email || ''}
									/>
									<CellDefault
										parentClassName={TABLE_ITEMS_CLASSNAMES.phone}
										text={volunteer.phoneNumber || ''}
									/>
									<CellDefault
										parentClassName={TABLE_ITEMS_CLASSNAMES.age}
										text={calculateUserAge(volunteer.birthDate) || 'No data'}
									/>
									<CellDefault
										parentClassName={TABLE_ITEMS_CLASSNAMES.gender}
										text={generateGenderName(volunteer.gender?.genderName)}
									/>
									<CellDefault
										parentClassName={TABLE_ITEMS_CLASSNAMES.location}
										text={generateLocation(volunteer.address)}
									/>
									{volunteer.organizations?.length ? (
										<CellDefault
											parentClassName={TABLE_ITEMS_CLASSNAMES.listOrganizations}
											// TODO: be should implement this
											boldDefaultOrg={true}
											text={getListVolunteers(
												volunteer.organizations,
												volunteer.defaultOrganizationId
											)}
										/>
									) : (
										<CellDefault
											parentClassName={TABLE_ITEMS_CLASSNAMES.listOrganizations}
											// TODO: be should implement this
											text="No organizations"
										/>
									)}
									<CellDefault
										parentClassName={TABLE_ITEMS_CLASSNAMES.totalTimeSpent}
										text={convertTimeFromSecondsToHours(volunteer.spendTime)}
									/>
									<div className={styles.action_cell}>
										<VolunteerTableDataContext.Provider value={volunteer}>
											<VolunteerActionCell />
										</VolunteerTableDataContext.Provider>
									</div>
									<ContactCell chatId={volunteer.chatId || ''} orgId="" />
									<PastOpportunitiesCell
										volunteerId={+volunteer.id}
										volunteerFullName={setFullName(volunteer?.firstName, volunteer?.lastName)}
									/>
								</TableRow>
							))}
						</TableBody>
					</TableMain>
				)}
				{volunteersRecordsList.length !== volunteersRecordsTotal && !errorState && (
					<Observer
						paginationSkip={volunteersRecordsList.length}
						setPagination={setPagination}
						isLoading={isLoading}
					/>
				)}
			</div>
		</div>
	);
};

export default VolunteersTable;
