import React, { FC, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import moment from 'moment-mini';
import cx from 'classnames';
import { Snackbar } from '@material-ui/core';
import { SnackbarCloseReason } from '@material-ui/core/Snackbar/Snackbar';
import { useInView } from 'react-intersection-observer';

// components
import StoreItemActions from './StoreTableActions';
import ImageCell from 'shared/components/Table/CellRenderers/ImageCell';
import CustomTooltip from 'shared/components/Tooltip';
import Loader from 'shared/components/Loader';

// hooks
import { useStoreItems } from 'hooks/store';

// icons
import { ReactComponent as ClockSvg } from 'assets/image/clock-challenges.svg';
import { ReactComponent as CopySvg } from 'assets/image/icons/copy-link.svg';
import { ReactComponent as PlaySVG } from 'assets/image/close-eye.svg';
import { ReactComponent as PauseSVG } from 'assets/image/open-eye.svg';
import { ReactComponent as ErrorIcon } from 'assets/image/error-icon.svg';
import { ReactComponent as NoStatusImage } from 'assets/image/no-items-image.svg';

// types
import { STORE_ITEM_POSSIBLE_SORT, StoreItemSort, StoreItemResponse } from '@joc/api-gateway/lib/api-client';
import { IStoreItemResponse, SortDirection } from '@joc/api-gateway';

// styles
import styles from './StoreTable.module.scss';
import { useQueryClient } from '@tanstack/react-query';
import SortableHeaderItem from './SortableHeaderItem';

type StoreTableProps = {
	search: string;
};

const StoreItemsTable: FC<StoreTableProps> = ({ search }) => {
	const queryClient = useQueryClient();
	const [errorToggleVisibility, setToggleErrorVisibility] = useState(false);
	const [totalFetchedStatuses, setTotalFetchedStatuses] = useState(0);
	const [sort, setSort] = useState<StoreItemSort>();
	const { data, isFetching, isFetched, status, fetchNextPage, hasNextPage, isFetchingNextPage } = useStoreItems(
		search,
		sort
	);

	const { ref, inView } = useInView({
		threshold: 1.0, // Trigger only when the element is fully in view
	});

	const toggleVisibility = useCallback((storeItem: IStoreItemResponse) => {
		if (!storeItem.isPublished) {
			if (storeItem.coupons?.length) {
				// visible store item by API
			} else {
				setToggleErrorVisibility(true);
			}
		}
	}, []);

	const handleClose = (event: SyntheticEvent<any>, reason?: SnackbarCloseReason) => {
		if (reason === 'clickaway') {
			return;
		}

		setToggleErrorVisibility(false);
	};

	useEffect(() => {
		if (inView && hasNextPage) {
			fetchNextPage().finally();
		}
	}, [inView, hasNextPage, fetchNextPage]);

	useEffect(() => {
		setTotalFetchedStatuses(
			([] as Array<StoreItemResponse>).concat(...(data?.pages.map(page => page?.records) || [])).length
		);
	}, [data]);

	useEffect(() => {
		return () => {
			queryClient.resetQueries().finally();
		};
	}, []);

	const handleSortChange = useCallback(
		(sortBy: STORE_ITEM_POSSIBLE_SORT) => {
			if (sort) {
				if (sort.sortBy === sortBy) {
					if (sort.sortDir === SortDirection.ASC) {
						setSort(prevState => StoreItemSort.fromJS({ ...prevState, sortDir: SortDirection.DESC }));
					} else {
						setSort(undefined);
					}
				} else {
					setSort(StoreItemSort.fromJS({ sortBy, sortDir: SortDirection.ASC }));
				}
			} else {
				setSort(StoreItemSort.fromJS({ sortBy, sortDir: SortDirection.ASC }));
			}
		},
		[sort]
	);

	return (
		<section
			className={cx(styles.storeTable_wrapper, {
				[styles.overflow__hidden]: status === 'error' || (isFetched && !totalFetchedStatuses),
				[styles.overflow_y__hidden]: status === 'pending' && !totalFetchedStatuses,
			})}
		>
			<table className={styles.storeTable}>
				<thead className={styles.storeTable_thead}>
					<tr>
						<th align="left" className={styles.storeTable_thead_photo}>
							Photo
						</th>
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<th align="left" className={styles.storeTable_thead_title}>
							<SortableHeaderItem
								sortBy={STORE_ITEM_POSSIBLE_SORT.Title}
								title="Title"
								handleSortChange={handleSortChange}
								sort={sort}
							/>
						</th>
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<th align="left" className={styles.storeTable_thead_price}>
							<SortableHeaderItem
								sortBy={STORE_ITEM_POSSIBLE_SORT.Price}
								title="Price in coins"
								handleSortChange={handleSortChange}
								sort={sort}
							/>
						</th>
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<th align="left" className={styles.storeTable_thead_createdDate}>
							<SortableHeaderItem
								sortBy={STORE_ITEM_POSSIBLE_SORT.CreateDate}
								title="Created Date"
								handleSortChange={handleSortChange}
								sort={sort}
							/>
						</th>
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<th align="left" className={styles.storeTable_thead_expirationTime}>
							<SortableHeaderItem
								sortBy={STORE_ITEM_POSSIBLE_SORT.ExpirationDate}
								title="Expiration Time"
								handleSortChange={handleSortChange}
								sort={sort}
							/>
						</th>
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<th align="left" className={styles.storeTable_thead_couponsLeft}>
							<SortableHeaderItem
								sortBy={STORE_ITEM_POSSIBLE_SORT.Quantity}
								title="Coupons Left"
								handleSortChange={handleSortChange}
								sort={sort}
							/>
						</th>
						<th align="left" className={styles.storeTable_thead_websiteLink}>
							Website Link
						</th>
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<th align="left" className={styles.storeTable_thead_visibility}>
							<SortableHeaderItem
								sortBy={STORE_ITEM_POSSIBLE_SORT.IsPublished}
								title="Visibility"
								handleSortChange={handleSortChange}
								sort={sort}
							/>
						</th>
						<th align="left" className={styles.storeTable_thead_actions} aria-label="Actions" />
					</tr>
				</thead>
				<tbody className={styles.storeTable_tbody}>
					{data?.pages.flatMap(page =>
						page?.records.map(item => (
							<tr
								key={item.id}
								className={cx({ [styles.storeTable_tbody_suspended]: !item.isPublished })}
							>
								<td align="left" className={styles.storeTable_tbody_photo}>
									<ImageCell
										imagePath={(item?.mediaPathes as unknown as Array<string>)?.[0]}
										title={item.title}
										withPopup={true}
									/>
								</td>
								<td align="left" className={styles.storeTable_tbody_title}>
									<span title={item.title}>{item.title}</span>
								</td>
								<td align="left" className={styles.storeTable_tbody_price}>
									<span title={String(item.price)}>{item.price}</span>
								</td>
								<td align="left" className={styles.storeTable_tbody_createdDate}>
									<div className={styles.storeTable_tbody_createdDate_wrapper}>
										<ClockSvg />
										<span>{moment(item.createDate).format('MMMM DD, YYYY')}</span>
									</div>
								</td>
								<td align="left" className={styles.storeTable_tbody_expirationTime}>
									<div className={styles.storeTable_tbody_expirationTime_wrapper}>
										<ClockSvg />
										<span>{moment(item.expirationDate).format('MMMM DD, YYYY, hh:mm A')}</span>
									</div>
								</td>
								<td align="left" className={styles.storeTable_tbody_couponsLeft}>
									<span title={String(item.quantity)}>{item.quantity}</span>
								</td>
								<td align="left" className={styles.storeTable_tbody_websiteLink}>
									<a
										href={item.productUrl}
										target="_blank"
										rel="noreferrer"
										aria-label={item.productUrl}
									>
										<CopySvg title={item.productUrl} width={24} height={24} />
									</a>
								</td>
								<td align="left" className={styles.storeTable_tbody_visibility}>
									<CustomTooltip title="Toggle visible\hidden Item" arrow={true} placement="top">
										{!item.isPublished ? (
											<PlaySVG width={24} height={24} onClick={() => toggleVisibility(item)} />
										) : (
											<PauseSVG width={24} height={24} onClick={() => toggleVisibility(item)} />
										)}
									</CustomTooltip>
								</td>
								<td align="left" className={styles.storeTable_tbody_actions}>
									<StoreItemActions storeItem={item} />
								</td>
							</tr>
						))
					)}
					<tr style={{ height: 0 }}>
						<td style={{ padding: 0 }}>
							<div ref={ref} style={{ height: '1px', visibility: 'hidden' }} />
						</td>
					</tr>
				</tbody>
			</table>

			{isFetchingNextPage || status === 'pending' ? <Loader /> : null}

			{!isFetching && !totalFetchedStatuses ? (
				<div className={styles.noItemsContainer}>
					<div className={styles.noItemsContainer__gradient} />
					<NoStatusImage className={styles.noItemsImage} />
					<div className={styles.noItemsTextContainer}>
						<span className={styles.noItemsTitle}>Oops</span>
						<span className={styles.noItemsText}>
							{status === 'error'
								? 'Something went wrong.'
								: 'There are currently no store items in the list. Create them to see them here.'}
						</span>
					</div>
				</div>
			) : null}

			<Snackbar open={errorToggleVisibility} autoHideDuration={4000} onClose={handleClose}>
				<div className={styles.storeTable__error}>
					<ErrorIcon width={24} height={24} />
					<div className={styles.storeTable__error__content}>
						<span className={styles.storeTable__error__content_title}>Error</span>
						<span className={styles.storeTable__error__content_message}>Item is missing coupons!</span>
					</div>
				</div>
			</Snackbar>
		</section>
	);
};

export default StoreItemsTable;
