import { FC, useState, useEffect, useRef } from 'react';
import { OrganisationResponse, SearchOrganizationRequest } from '@joc/api-gateway/lib/api-client';
import { API } from 'core/API';
import { useDebounceValue } from 'core/customHooks';

import styles from './InputSearchOrg.module.scss';

type InputSearchOrgProps = {
	placeholder: string;
	fieldName: string;
	fieldValue?: string;
	id?: string;
	classList?: Array<string>;
	parentClass?: string;
	value?: string;
	selectHandler: (value: string | null) => void;
	autoFocus?: boolean;
	userOrganizations?: Array<OrganisationResponse>;
};

const InputSearchOrg: FC<InputSearchOrgProps> = ({
	placeholder = '',
	id,
	fieldValue,
	selectHandler,
	userOrganizations,
}: // autoFocus = false,
InputSearchOrgProps) => {
	const [isDropOpen, setDropOpen] = useState(false);
	const [organizationList, setOrganizationList] = useState<Array<OrganisationResponse>>();
	const [isResultSelected, setResultSelected] = useState(false);
	const changeHandlerInput = async (inputText: string) => {
		if (isResultSelected) return;
		if (!inputText.length) selectHandler('');
		try {
			if (!userOrganizations) {
				const response = await API.findOrganisation(
					undefined,
					SearchOrganizationRequest.fromJS({
						pagination: { skip: 0, take: 6 },
						fullTextSearch: { value: inputText, fields: ['organizationName'] },
					})
				);
				setOrganizationList(response.records);
			} else {
				setOrganizationList(
					userOrganizations
						.filter(org =>
							inputText ? org.organizationName.toLowerCase().includes(inputText.toLowerCase()) : org
						)
						.sort((a, b) => {
							if (a.organizationName < b.organizationName) {
								return -1;
							}
							if (a.organizationName > b.organizationName) {
								return 1;
							}
							return 0;
						})
				);
			}

			setDropOpen(true);
		} catch (error: any) {
			console.warn(error);
		}
	};
	const selectOrganization = (a: OrganisationResponse) => {
		setInputValue(a.organizationName);
		selectHandler(a.id);
		if (!userOrganizations) setOrganizationList([]);
		setDropOpen(false);
		setResultSelected(true);
	};

	useEffect(() => {
		if (userOrganizations) {
			if (userOrganizations.length) {
				const defaultOrganization = userOrganizations.find(org => org.id === fieldValue);
				if (defaultOrganization) {
					setInputValue(defaultOrganization.organizationName);
					selectHandler(defaultOrganization.id);
					setDropOpen(false);
					setResultSelected(true);
					setOrganizationList(
						userOrganizations.sort((a, b) => {
							if (a.organizationName < b.organizationName) {
								return -1;
							}
							if (a.organizationName > b.organizationName) {
								return 1;
							}
							return 0;
						})
					);
				}
			} else {
				setInputValue('Private');
				selectHandler('Private');
				setDropOpen(false);
				setResultSelected(true);
			}
		}
	}, [userOrganizations, fieldValue]);
	const [inputValue, setInputValue] = useState<string | undefined>(undefined);

	const debouncedValue = useDebounceValue(inputValue, 500);

	const ref = useRef<HTMLInputElement>(null);

	const selectRef = useRef<HTMLDivElement>(null);

	const handleClickOutside = (event: Event) => {
		// isDropOpen && setFieldTouched(fieldName, true);
		if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
			setDropOpen(false);
		}
	};

	const setFocus = (): void => {
		if (ref && ref.current) {
			ref.current.focus();
		}
	};

	useEffect(() => {
		if (debouncedValue !== undefined) changeHandlerInput(debouncedValue);
	}, [debouncedValue]);

	useEffect(() => {
		if (!fieldValue && inputValue !== undefined) setInputValue('');
	}, [fieldValue]);

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => document.removeEventListener('click', handleClickOutside, true);
	}, [isDropOpen]);

	return (
		<div className={styles.input__search}>
			<div className={styles.input__search_icon} onClick={setFocus} />
			<input
				id={id && id}
				type="text"
				value={inputValue || ''}
				placeholder={placeholder}
				ref={ref}
				onChange={e => setInputValue(e.target.value)}
				onClick={() => {
					setResultSelected(false);
					if (userOrganizations) setDropOpen(true);
				}}
			/>
			{isDropOpen && !isResultSelected && (
				<div ref={selectRef} className={styles.dropWrap}>
					{organizationList && organizationList?.length > 0 ? (
						organizationList.map(el => (
							<div key={el.id} className={styles.dropDown} onClick={() => selectOrganization(el)}>
								{el.id === fieldValue ? (
									<b>{el.organizationName}</b>
								) : (
									<span>{el.organizationName}</span>
								)}
							</div>
						))
					) : (
						<div className={styles.dropDown}>no organization found</div>
					)}
				</div>
			)}
		</div>
	);
};

export default InputSearchOrg;
