import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { disableBodyScroll, enableBodyScroll, BodyScrollOptions } from 'body-scroll-lock';
import ButtonCross from '../Buttons/ButtonCross';
import styles from './PopupContainer.module.scss';

const disableScrollOptions: BodyScrollOptions = {
	reserveScrollBarGap: true,
};

type PopupContainerPropsType = {
	children: ReactNode;
	setIsShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
	isDisablePadding?: boolean;
	isDisableCloseButton?: boolean;
	isFixedStyle?: boolean;
	withoutTopMargin?: boolean;
	parentClassName?: string;
};

const PopupContainer: FC<PopupContainerPropsType> = ({
	setIsShowPopup,
	children,
	isDisablePadding,
	isDisableCloseButton,
	isFixedStyle,
	parentClassName,
	withoutTopMargin = false,
}: PopupContainerPropsType) => {
	const [isClosePopup, setIsClosePopup] = useState<boolean>(false);
	const containerRef = useRef<HTMLDivElement>(null);
	const contentRef = useRef<HTMLDivElement>(null);

	const closePopupClickHandler = () => {
		setIsClosePopup(true);
		setTimeout(() => setIsShowPopup(false), 200);
	};

	useEffect(() => {
		// document.addEventListener('click', handleClickOutside, true);

		disableBodyScroll(document.body, disableScrollOptions);
		const currentPageYOffset = pageYOffset;
		window.scrollTo(0, currentPageYOffset);
		return () => {
			enableBodyScroll(document.body);
			// document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);

	// const handleClickOutside = (event: Event) => {
	// 	if (contentRef.current && !contentRef.current.contains(event.target as Node)) {
	// 		closePopupClickHandler();
	// 	}
	// };

	return (
		<div
			className={cx(styles.wrapper, {
				[styles.close]: isClosePopup,
				[styles.padding_disabled]: isDisablePadding,
				[styles.fixed]: isFixedStyle,
				[styles.withoutTop]: withoutTopMargin,
			})}
			ref={containerRef}
		>
			<div className={cx(styles.content__container, parentClassName)} ref={contentRef}>
				{!isDisableCloseButton && (
					<ButtonCross clickHandler={closePopupClickHandler} parentClassName={styles.button_cross} />
				)}
				{children}
			</div>
		</div>
	);
};

export default PopupContainer;
