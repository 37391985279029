import React, { FC, useMemo, useState } from 'react';
import { Formik } from 'formik';
import moment from 'moment-mini';

// components
import Dialog from 'shared/components/Dialog';
import ButtonCross from 'shared/components/Buttons/ButtonCross';
import { StoreItemForm } from '../../StoreForms';

// hooks
import { useCreateStoreItem } from 'hooks/store/items/useCreateStoreItem';
import { useEditStoreItem } from 'hooks/store/items/useEditStoreItem';

// helpers
import { sendFilesToS3Bucket } from 'core/functions';
import { prepareImages } from 'shared/components/SortableImagesListField';

// validation
import { storeItemValidationSchema } from '../../StoreForms/StoreItemForm/validation';

// types
import { IStoreItemResponse, IUpdateStoreItemRequest } from '@joc/api-gateway';
import { StoreItemFormType, StoreItemResponseType } from '../../types';

import styles from './StoreItemPopup.module.scss';

type StoreItemPopupProps = {
	storeItem?: StoreItemResponseType;
	open?: boolean;
	onClose?: () => void;
};

const StoreItemPopup: FC<StoreItemPopupProps> = ({ storeItem, open = true, onClose }) => {
	const { createStoreItem } = useCreateStoreItem();
	const { editStoreItem } = useEditStoreItem();
	const [isSaving, setIsSaving] = useState(false);

	const initialValues: StoreItemFormType = useMemo(() => {
		const expirationDateInfo = {
			endDate: storeItem?.expirationDate || undefined,
			endTime: storeItem?.expirationDate ? moment(storeItem.expirationDate).format('HH:mm') : undefined,
		};

		if (storeItem)
			return {
				...storeItem,
				mediaPathes: prepareImages(storeItem.mediaPathes),
				...expirationDateInfo,
			} as unknown as StoreItemFormType;

		return {
			title: '',
			subtitle: '',
			description: '',
			productUrl: '',
			mediaPathes: [],
			price: null,
			...expirationDateInfo,
		};
	}, [storeItem]);

	const handleSubmitClickHandler = async (values: StoreItemFormType) => {
		setIsSaving(true);
		const { mediaPathes, ...body } = values;

		const uploadedImages = await Promise.all(
			mediaPathes.map(async image => {
				if (image.file) {
					const [imageUrl] = await sendFilesToS3Bucket([image.file]);

					return imageUrl;
				}

				return Promise.resolve(image.src);
			})
		);

		const expirationDate =
			values.endDate && values.endTime
				? moment(values.endDate)
						.set({
							hours: moment(values.endTime, 'HH:mm').hours(),
							minutes: moment(values.endTime, 'HH:mm').minutes(),
						})
						.toDate()
				: undefined;

		if (storeItem) {
			editStoreItem({
				id: storeItem.id,
				storeItem: {
					...body,
					expirationDate,
					mediaPathes: uploadedImages,
				} as unknown as IUpdateStoreItemRequest,
			});
		} else {
			createStoreItem({ ...body, expirationDate, mediaPathes: uploadedImages } as unknown as IStoreItemResponse);
		}

		closePopupClickHandler();
		setIsSaving(false);
	};

	const closePopupClickHandler = () => onClose?.();

	return (
		<Dialog open={open} maxWidth="xl" isFullScreen={true}>
			<section className={styles.storeItemPopup}>
				<span className={styles.storeItemPopup__title}>{storeItem ? 'Edit' : 'Add'} item</span>
				<ButtonCross clickHandler={closePopupClickHandler} parentClassName={styles.storeItemPopup__close} />

				<Formik
					initialValues={initialValues}
					validationSchema={storeItemValidationSchema}
					onSubmit={handleSubmitClickHandler}
				>
					<StoreItemForm isNew={!storeItem} isSaving={isSaving} onCancel={closePopupClickHandler} />
				</Formik>
			</section>
		</Dialog>
	);
};
export default StoreItemPopup;
