import React, { FC, useEffect, useMemo, useState } from 'react';

// components
import Dialog from 'shared/components/Dialog';
import ButtonCross from 'shared/components/Buttons/ButtonCross';
import { StoreCouponsTable } from '../../StoreTables';
import StoreAddCoupons from './StoreAddCoupons';

// types, enums
import StoreCouponsTabs, { CouponsTabs } from './StoreCouponsTabs';
import { IStoreItemResponse } from '@joc/api-gateway';

// styles
import styles from '../StoreItemPopup/StoreItemPopup.module.scss';

type StoreItemCouponsPopupProps = {
	open: boolean;
	storeItem: IStoreItemResponse;
	onClose?: () => void;
};

const StoreItemCouponsPopup: FC<StoreItemCouponsPopupProps> = ({ open = true, storeItem, onClose }) => {
	const [currentTab, setCurrentTab] = useState<CouponsTabs>();

	const closePopupClickHandler = () => {
		onClose?.();
	};

	const ActiveManageCouponsTab = useMemo(() => {
		switch (currentTab) {
			case CouponsTabs.CouponsList:
				return <StoreCouponsTable />;
			case CouponsTabs.AddCoupons:
				return <StoreAddCoupons />;
			default:
				return <></>;
		}
	}, [currentTab]);

	useEffect(() => {
		setCurrentTab(storeItem.coupons?.length ? CouponsTabs.CouponsList : CouponsTabs.AddCoupons);
	}, [storeItem]);

	return (
		<Dialog open={open} maxWidth="xl" isFullScreen={true}>
			<section className={styles.storeItemPopup}>
				<span className={styles.storeItemPopup__title}>Manage coupons</span>
				<ButtonCross clickHandler={closePopupClickHandler} parentClassName={styles.storeItemPopup__close} />
				<StoreCouponsTabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
				{ActiveManageCouponsTab}
			</section>
		</Dialog>
	);
};

export default StoreItemCouponsPopup;
