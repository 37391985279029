import { FC, useCallback, useEffect, useState } from 'react';
import { RolesResponse, SearchUsersByAdminResponse, SearchUsersForAdminRequest } from '@joc/api-gateway';
import { TABLE_HEADERS, TABLE_ITEMS_CLASSNAMES } from 'core/constants';
import { selectorGetError } from 'redux/error-service/selector';
import { Store } from 'redux/root';
// import { OrganizationsTableContext } from 'shared/components/Table/AdminOrgTable';
import AvatarCell from 'shared/components/Table/CellRenderers/AvatarCell';
import CellDefault from 'shared/components/Table/CellRenderers/CellDefault';
import FullNameCell from 'shared/components/Table/CellRenderers/FullNameCell';
import OrgContactCell from 'shared/components/Table/CellRenderers/OrgContactCell';
import TableBody from 'shared/components/Table/TableBody';
import TableHead from 'shared/components/Table/TableHead';
import TableHeadCell from 'shared/components/Table/TableHead/TableHeadCell';
import TableMain from 'shared/components/Table/TableMain';
import TableRow from 'shared/components/Table/TableRow';
import { connect, ConnectedProps } from 'react-redux';
import { getOrgUsers } from 'redux/organization-service/actions';
import Loader from 'shared/components/Loader';
import { INITIAL_PAGINATION } from 'core/constants/INITIAL_PAGINATION';
import styles from './PopupUsersTable.module.scss';

type PopupUsersTablePearentProps = {
	orgId: string;
};

const PopupUsersTable: FC<PopupUsersTableProps> = ({ error, orgId }: PopupUsersTableProps) => {
	const [users, setUsers] = useState<SearchUsersByAdminResponse>();
	// const [isLoading, setIsLoading] = useState(false);
	const [pagination, setPagination] = useState(INITIAL_PAGINATION);

	const getOrganizationUsersRespons = useCallback(
		async (organizationId: string, pagination: typeof INITIAL_PAGINATION) => {
			// setIsLoading(true);
			try {
				const usersRespons = await getOrgUsers({ organizationId, pagination } as SearchUsersForAdminRequest);
				setUsers(usersRespons);
				// setIsLoading(false);
			} catch (error: any) {
				// setIsLoading(false);
				throw error;
			}
		},
		[pagination]
	);

	const getRoleNames = (roles: Array<RolesResponse> | undefined) => {
		const listRoles: Array<string> = [];
		roles?.forEach(role => listRoles.push(role.roleName));
		return listRoles.toString();
	};

	useEffect(() => {
		getOrganizationUsersRespons(orgId, pagination);
		// return () => {
		// 	setUsers(users?.records = []);
		// };
	}, []);

	return (
		<div>
			<TableMain>
				<TableHead className={styles.table__header} callChild="users">
					<TableHeadCell
						itemClassName={TABLE_ITEMS_CLASSNAMES.avatar}
						text={TABLE_HEADERS.avatar}
						isSortDisabled
					/>
					<TableHeadCell
						itemClassName={TABLE_ITEMS_CLASSNAMES.fullName}
						text={TABLE_HEADERS.fullName}
						isSortDisabled
					/>
					<TableHeadCell
						itemClassName={TABLE_ITEMS_CLASSNAMES.role}
						text={TABLE_HEADERS.role}
						isSortDisabled
					/>
					<TableHeadCell
						itemClassName={TABLE_ITEMS_CLASSNAMES.contacts}
						text={TABLE_HEADERS.contacts}
						isSortDisabled
					/>
					{/* <TableHeadCell
						itemClassName={TABLE_ITEMS_CLASSNAMES.position}
						text={TABLE_HEADERS.position}
						isSortDisabled
					/> */}
					{/* <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.options} /> */}
				</TableHead>
				{users?.records.length ? (
					<TableBody>
						{users?.records.map((user, index: number) => {
							return (
								<TableRow
									// key={user.id}
									callChild="users"
									// suspend={user.userActiveStatus.status === ORGANISATION_ACTIVE_STATUS.SUSPENDED}
								>
									<AvatarCell imagePath={user.imagePath} user={user} withPopup={true} />
									<FullNameCell firstName={user.firstName} lastName={user.lastName} />
									<CellDefault
										parentClassName={TABLE_ITEMS_CLASSNAMES.role}
										text={getRoleNames(user.roles)}
									/>
									<OrgContactCell phone={user.phoneNumber} email={user.email} />
									{/* <div className={styles.actions__content}>
										<VolunteerTableDataContext.Provider value={user}>
											<VolunteerActionCell />
										</VolunteerTableDataContext.Provider>
									</div> */}
								</TableRow>
							);
						})}
					</TableBody>
				) : (
					<Loader />
				)}
			</TableMain>
		</div>
	);
};

const mapStateToProps = (store: Store) => ({
	error: selectorGetError(store),
});

const connector = connect(mapStateToProps);

type PopupUsersTableProps = ConnectedProps<typeof connector> & PopupUsersTablePearentProps;

export default connector(PopupUsersTable);
