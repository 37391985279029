import ZoomPhotoPopup from '../../../ZoomPhotoPopup';
import styles from './ImageCell.module.scss';
import defaultStyles from '../CellDefault.module.scss';
import cx from 'classnames';
import React, { FC, useState } from 'react';

type ImageCellProps = {
	withPopup?: boolean;
	imagePath?: string;
	title?: string;
};

const ImageCell: FC<ImageCellProps> = ({ withPopup = false, imagePath, title = 'Image title' }) => {
	const [isShowPopup, setIsShowPopup] = useState(false);

	return imagePath ? (
		<>
			{withPopup ? (
				<ZoomPhotoPopup
					urlPhoto={imagePath}
					setIsShowPopup={setIsShowPopup}
					isShowPopup={isShowPopup}
					classNames={[styles.image, defaultStyles.main]}
					title={title}
				/>
			) : (
				<div className={cx(styles.image, defaultStyles.main)}>
					<img src={imagePath} alt="avatar" />
				</div>
			)}
		</>
	) : (
		<></>
	);
};

export default ImageCell;
