import { FC, useState } from 'react';

// components
import MainPageLayout from 'components/Organization/MainPageLayout';
import { StoreItemsTable } from './StoreTables';
import { StoreItemPopup } from './StorePopups';
import StoreActions from './StoreActions';

import styles from './SuperAdminStore.module.scss';

const SuperAdminStore: FC = () => {
	const [popupCreateOpen, setPopupCreateOpen] = useState(false);
	const [search, setSearch] = useState('');

	const togglePopupHandler = () => setPopupCreateOpen(!popupCreateOpen);

	const searchHandler = (value: string) => setSearch(value);

	return (
		<MainPageLayout>
			<div className={styles.storeItems}>
				<span className={styles.storeItems_title}>Store</span>
				<section className={styles.storeItems_container}>
					<StoreActions onCreateStoreItem={togglePopupHandler} onSearchStoreItem={searchHandler} />
					<StoreItemsTable search={search} />
					{popupCreateOpen ? <StoreItemPopup open={popupCreateOpen} onClose={togglePopupHandler} /> : null}
				</section>
			</div>
		</MainPageLayout>
	);
};

export default SuperAdminStore;
