import { FC } from 'react';
import styles from './StoreCouponsTable.module.scss';

type StoreCouponsTableProps = {};

const StoreCouponsTable: FC<StoreCouponsTableProps> = () => {
	return (
		<section className={styles.couponsTable_wrapper}>
			<table className={styles.couponsTable}>
				<thead>
					<tr>
						<td align="left">ID</td>
						<td align="left">Coupons</td>
						<td align="left">Status</td>
						<td align="left">Actions</td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td align="left">ID</td>
						<td align="left">Coupons</td>
						<td align="left">Status</td>
						<td align="left">Actions</td>
					</tr>
				</tbody>
			</table>
		</section>
	);
};

export default StoreCouponsTable;
