import * as Yup from 'yup';

const mediaPatesSchema = Yup.array().test('required', 'Image is required', function (value) {
	if (value) {
		return !!value.length;
	}

	return true;
});

const titleSchema = Yup.string()
	.min(2, 'Title should be no less than 2 characters')
	.trim()
	.required('Title is required');

const descriptionSchema = Yup.string()
	.test('Description is required', 'Description is required', description => {
		const element = document.createElement('div');
		element.innerHTML = description?.trim() || '';

		return !!element.innerText.trim()?.length;
	})
	.test('minDescription', 'Description should be no less than 2 characters', description => {
		const element = document.createElement('div');
		element.innerHTML = description || '';

		return !(element.innerText?.length !== 0 && element.innerText?.length <= 2);
	})
	.required('Description is required');

const priceSchema = Yup.number()
	.integer('Price should have digits only')
	.required('Price is required')
	.typeError('Price must be a number')
	.nullable();

const urlSchema = Yup.string().url('Please, fill valid URL-Link').required('Link to Website is required');

export const storeItemValidationSchema = Yup.object().shape({
	mediaPathes: mediaPatesSchema,
	title: titleSchema,
	description: descriptionSchema,
	price: priceSchema,
	productUrl: urlSchema,
});
