import React, { CSSProperties, FC } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import RemoveIcon from 'assets/image/icons/remove-icon.png';

import styles from '../StoreImageField.module.scss';

type ImageItemProps = {
	id: string;
	src: string | ArrayBuffer;
	// eslint-disable-next-line no-unused-vars
	onRemove: (id: string) => void;
};

const ImageItem: FC<ImageItemProps> = ({ id, src, onRemove }) => {
	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
		id,
	});

	const style: CSSProperties = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	const removeClickHandler = () => {
		onRemove(id);
	};

	return (
		<div ref={setNodeRef} style={style} className={styles.storeImageField__images_item}>
			<img
				src={RemoveIcon}
				alt="remove-icon"
				className={styles.storeImageField__images_item_remove}
				onClick={removeClickHandler}
			/>
			<img
				src={src as string}
				alt={`Item ${id}`}
				className={styles.storeImageField__images_item_image}
				{...attributes}
				{...listeners}
			/>
		</div>
	);
};

export default ImageItem;
