import React, { FC } from 'react';
import cx from 'classnames';
import Appeal from 'shared/components/Appeal/DefaultRenderer';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import styles from './CongratsPopup.module.scss';

type CongratsPopupProps = {
	doneButtonClickHandler: () => void;
	title?: string;
	subtitle: string;
	isFullHeight?: boolean;
	absoluteDisable?: boolean;
	isLiveScreen?: boolean;
	isEditVolunteer?: boolean;
};

const CongratsPopup: FC<CongratsPopupProps> = ({
	doneButtonClickHandler,
	title,
	subtitle,
	isFullHeight,
	absoluteDisable,
	isLiveScreen,
	isEditVolunteer,
}: CongratsPopupProps) => {
	return (
		<div
			className={cx(styles.container, {
				[styles.absolute_disable]: absoluteDisable,
				[styles.container_live_screen]: isLiveScreen,
				[styles.volunteer]: isEditVolunteer,
			})}
		>
			<Appeal isHeightDisable={!isFullHeight} withoutImage={isEditVolunteer}>
				<div className={styles.appeal}>
					<span className="title">{title || 'Congrats!'}</span>
					<span className={styles.subtitle}>{subtitle}</span>
				</div>
				<div className={styles.options}>
					<ButtonDefault classList={['lg', 'primary']} title="Done" clickHandler={doneButtonClickHandler} />
				</div>
			</Appeal>
		</div>
	);
};

export default CongratsPopup;
