import { Dispatch, FC, SetStateAction } from 'react';
//components
import PopupContainer from '../PopupContainer';
import WhiteContainer from '../WhiteContainer';
//styles
import styles from './ZoomPhotoPopup.module.scss';
import cx from 'classnames';

type ZoomPhotoPopupParentProps = {
	urlPhoto?: string;
	isShowPopup: boolean;
	setIsShowPopup: Dispatch<SetStateAction<boolean>>;
	alt?: string;
	classNames?: Array<string>;
	title?: string;
};

const ZoomPhotoPopup: FC<ZoomPhotoPopupParentProps> = ({
	urlPhoto,
	alt,
	classNames,
	isShowPopup,
	setIsShowPopup,
	title,
}: ZoomPhotoPopupParentProps) => {
	const imageClickHandler = () => setIsShowPopup(true);

	return (
		<>
			<div className={cx(classNames)}>
				<img src={urlPhoto} alt={alt || 'photo'} className={styles.preview} onClick={imageClickHandler} />
			</div>
			{isShowPopup && (
				<PopupContainer
					isFixedStyle={true}
					withoutTopMargin={true}
					setIsShowPopup={setIsShowPopup}
					parentClassName={styles.popup}
				>
					<WhiteContainer title={title || ''} parentClassName={styles.wrapper}>
						<div className={styles.wrapper}>
							<img className={styles.logo} src={urlPhoto} alt="" />
						</div>
					</WhiteContainer>
				</PopupContainer>
			)}
		</>
	);
};

export default ZoomPhotoPopup;
