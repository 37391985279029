import React, { Dispatch, FC, SetStateAction, useCallback, useMemo, useRef } from 'react';
import moment from 'moment-mini';
import { toBlob } from 'html-to-image';
import { IChallengeResponse } from '@joc/api-gateway';

import { ReactComponent as ArrowSvg } from 'assets/image/arrow.svg';
import { ReactComponent as BannerBackgroundImage } from 'assets/image/challenge-banner.svg';
import { ReactComponent as DescriptionTopConfetti } from 'assets/image/description-top-confetti.svg';
import ClockPng from 'assets/image/clock-png.png';

import { ReactComponent as ArrowDownSvg } from 'assets/image/finger-arrow-down.svg';
import { ReactComponent as IdeaIcon } from 'assets/image/idea.svg';
import { ReactComponent as DownloadSvg } from 'assets/image/download-without-padding.svg';

import { ReactComponent as DescriptionBottomConfetti } from 'assets/image/description-bottom-confetti.svg';

import Confetti from 'assets/image/confetti.png';
import { ReactComponent as ConfettiSvg } from 'assets/image/confetti.svg';

import styles from './ChallengePreview.module.scss';

import s from '../../../../../components/Organization/Placeholders/EditPlaceholderDialog/EditPlaceholderDialog.module.scss';

import { getUserPhotoSrc } from '../../../../../core/functions';
import ButtonDefault from '../../../Buttons/ButtonsDefault';
import ButtonCross from '../../../Buttons/ButtonCross';
import DialogStyled from '../../../../../components/Organization/Placeholders/EditPlaceholderDialog/DialogStyled';

type ChallengesPreviewProps = {
	previewData: Partial<IChallengeResponse> | null;
	handlePreviewOpen: Dispatch<SetStateAction<Partial<IChallengeResponse> | null>>;
};

const ChallengesPreview: FC<ChallengesPreviewProps> = ({ previewData, handlePreviewOpen }) => {
	const parsedImagePath = useMemo(() => getUserPhotoSrc(previewData?.imagePath, undefined), [previewData?.imagePath]);
	const previewRef = useRef<HTMLDivElement>(null);

	const handleDownloadPreviewImage = useCallback(async () => {
		if (previewRef?.current) {
			toBlob(previewRef.current, { cacheBust: true, backgroundColor: 'white' })
				.then(previewImageUrl => {
					const link = document.createElement('a');
					link.download = 'preview.png';

					link.href = previewImageUrl ? URL.createObjectURL(previewImageUrl) : '';

					link.click();
				})
				.catch(err => {
					console.log(err);
				});
		}
	}, [previewRef]);

	return (
		<DialogStyled open={!!previewData} maxWidth="xl">
			<section className={s.dialog} style={{ width: 464 }}>
				<div className={s.dialog__header}>
					<DownloadSvg
						width={14}
						height={14}
						style={{ cursor: 'pointer' }}
						onClick={handleDownloadPreviewImage}
					/>
					<ButtonCross
						clickHandler={() => handlePreviewOpen(null)}
						parentClassName={s.dialog__header_button}
					/>
				</div>
				{previewData && (
					<section className={styles.preview}>
						<div
							className={styles.preview__main_banner}
							style={{
								backgroundImage: `url(${Confetti}), linear-gradient(294.14deg, #ffb64a 8.03%, #ffd34a 85.42%, #ffe24a 105.62%)`,
							}}
						>
							<div className={styles.preview__main_banner__dataBlock}>
								<span className={styles.preview__main_banner__dataBlock_challengeTitle}>
									{previewData.name}
								</span>
								<span className={styles.preview__main_banner__dataBlock_challengeDescription}>
									{previewData.title}
								</span>
								<div className={styles.preview__main_banner__dataBlock_navButton}>
									<span className={styles.preview__main_banner__dataBlock_navText}>Explore</span>
									<ArrowSvg className={styles.preview__main_banner__dataBlock_navArrow} />
								</div>
							</div>

							<img
								className={styles.preview__main_banner__dataBlock_imgBlock}
								src={parsedImagePath}
								alt="Challenge"
							/>
						</div>
						<div ref={previewRef}>
							<div className={styles.preview__confetti}>
								<ConfettiSvg className={styles.before} />
								<ConfettiSvg className={styles.after} />
							</div>
							<div className={styles.preview__banner}>
								<BannerBackgroundImage />
								<div className={styles.preview__banner__dataBlock}>
									<div className={styles.preview__banner__dataBlock_title}>{previewData.title}</div>
									<img
										className={styles.preview__banner__dataBlock_imgBlock}
										src={parsedImagePath}
										alt="Challenge"
									/>
								</div>
							</div>

							<div className={styles.preview__description}>
								<div className={styles.preview__description_content}>
									<DescriptionTopConfetti className={styles.preview__description__confetti_top} />
									{(previewData?.description && (
										<section dangerouslySetInnerHTML={{ __html: previewData.description }} />
									)) ||
										null}
									<div className={styles.preview__description_deadline}>
										<img src={ClockPng} alt="deadline" />
										<span>Deadline: {moment(previewData.endDate).format('MMMM DD, YYYY')}</span>
									</div>
									<DescriptionBottomConfetti
										className={styles.preview__description__confetti_bottom}
									/>
								</div>
							</div>

							{previewData?.chesedIdeas?.filter(idea => idea)?.length ? (
								<div className={styles.preview__chesedIdeas}>
									<div className={styles.preview__chesedIdeas_title}>
										<ArrowDownSvg />
										<span>Chesed ideas:</span>
									</div>
									{previewData.chesedIdeas
										?.filter(idea => idea)
										.map((idea, index) => (
											<div className={styles.preview__chesedIdeas_item} key={index}>
												<IdeaIcon style={{ minWidth: 24, minHeight: 24 }} />
												<span>{idea}</span>
											</div>
										))}
								</div>
							) : null}

							<div className={styles.preview__button_wrap}>
								<ButtonDefault
									classList={['primary', 'extra_lg']}
									parentClassName={styles.preview__button}
									fontPlusJakartaSans={true}
									title="Let’s go!"
								/>
							</div>
						</div>
					</section>
				)}
			</section>
		</DialogStyled>
	);
};

export default ChallengesPreview;
