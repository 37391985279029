import React, { FC } from 'react';
import { Field, FieldProps, Form, useFormikContext } from 'formik';
import cx from 'classnames';

// components
import SortableImagesListField from 'shared/components/SortableImagesListField';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import InputText from 'shared/components/FormInputs/InputText';
import Editor from 'shared/components/Editor';
import InputErrorHint from 'shared/components/InputErrorHint';
import InlineDatePicker from 'shared/components/Pickers/InlineDatePicker';
import CustomTimePicker from 'shared/components/Pickers/CustomTimePicker';
import Loader from '../../../../../shared/components/Loader';

// types
import { StoreItemFormType } from '../../types';

// styles
import styles from './StoreItemForm.module.scss';

type StoreItemFormTypeProps = {
	isNew: boolean;
	isSaving: boolean;
	onCancel: () => void;
};

const StoreItemForm: FC<StoreItemFormTypeProps> = ({ isNew, isSaving, onCancel }) => {
	const { dirty, isValid, setFieldTouched, setFieldValue } = useFormikContext<StoreItemFormType>();

	const handleCancel = () => onCancel();

	const changeDateHandler = (field: string, value: Date) => {
		setFieldTouched(field, true).finally();
		setFieldValue(field, value).finally();
	};

	const changeTimeHandler = (field: string, value?: string) => {
		setFieldTouched(field, true).finally();
		setFieldValue(field, value).finally();
	};

	return (
		<Form className={styles.storeItemForm}>
			<SortableImagesListField fieldName="mediaPathes" />
			<Field name="title">
				{({ field, meta, form }: FieldProps<string, StoreItemFormType>) => (
					<div className={styles.storeItemForm__item}>
						<label className={styles.storeItemForm__item_label} htmlFor="title">
							Title<span className={styles.storeItemForm__item_required}>*</span>
						</label>
						<InputText
							id="title"
							field={field}
							placeholder="Enter name of the product"
							setFieldValue={form.setFieldValue}
							parentClassName={styles.dialog__form_item__textfield}
						/>
						{meta.error && meta.touched && <InputErrorHint errorText={meta.error} disableMargin={true} />}
					</div>
				)}
			</Field>

			<Field name="subtitle">
				{({ field, form }: FieldProps<string, StoreItemFormType>) => (
					<div className={styles.storeItemForm__item}>
						<label className={styles.storeItemForm__item_label} htmlFor="subtitle">
							Subtitle
						</label>
						<InputText
							id="subtitle"
							field={field}
							placeholder="Item subtitle (optional)"
							setFieldValue={form.setFieldValue}
							parentClassName={styles.dialog__form_item__textfield}
						/>
					</div>
				)}
			</Field>

			<Field name="description">
				{({ form, meta, field }: FieldProps<string, StoreItemFormType>) => (
					<div className={styles.storeItemForm__item}>
						<label className={styles.storeItemForm__item_label} htmlFor="description">
							Description<span className={styles.storeItemForm__item_required}>*</span>
						</label>
						<Editor
							fieldValue={field.value}
							onChange={value => form.setFieldValue('description', value)}
							onBlur={() => form.setFieldTouched(field.name, true)}
							placeholder="Enter your description"
							isShowToolbar={true}
						/>
						{meta.error && meta.touched && <InputErrorHint errorText={meta.error} disableMargin={true} />}
					</div>
				)}
			</Field>

			<Field name="price">
				{({ field, meta, form }: FieldProps<string, StoreItemFormType>) => (
					<div className={styles.storeItemForm__item}>
						<label className={styles.storeItemForm__item_label} htmlFor="price">
							Price (in coins)<span className={styles.storeItemForm__item_required}>*</span>
						</label>
						<InputText
							id="price"
							type="number"
							field={field}
							placeholder="Item price"
							setFieldValue={form.setFieldValue}
							parentClassName={styles.dialog__form_item__textfield}
						/>
						{meta.error && meta.touched && <InputErrorHint errorText={meta.error} disableMargin={true} />}
					</div>
				)}
			</Field>

			<div className={styles.storeItemForm__item_pickers}>
				<Field name="endDate">
					{({ field, meta }: FieldProps) => (
						<div className={cx(styles.storeItemForm__item, styles.storeItemForm__item_pickers_field)}>
							<label className={styles.storeItemForm__item_label} htmlFor={field.name}>
								Expiration Date
							</label>
							<InlineDatePicker
								fieldName={field.name}
								fieldValue={field.value}
								setFieldValue={changeDateHandler}
								availableFuture={true}
								inputParentClassNames={styles.storeItemForm__item_pickers_item}
								cleareble={true}
							/>
							{meta.error && meta.touched && (
								<InputErrorHint errorText={meta.error} disableMargin={true} />
							)}
						</div>
					)}
				</Field>
				<Field name="endTime">
					{({ field, meta }: FieldProps) => (
						<div className={cx(styles.storeItemForm__item, styles.storeItemForm__item_pickers_field)}>
							<label className={styles.storeItemForm__item_label} htmlFor={field.name}>
								Expiration time
							</label>
							<CustomTimePicker
								fieldName={field.name}
								fieldValue={field.value && field.value}
								setFieldValue={changeTimeHandler}
								setFieldTouched={setFieldTouched}
								inputParentClassNames={styles.storeItemForm__item_pickers_item}
								cleareble={true}
							/>
							{meta.error && meta.touched && (
								<InputErrorHint errorText={meta.error} disableMargin={true} />
							)}
						</div>
					)}
				</Field>
			</div>

			<Field name="productUrl">
				{({ field, meta, form }: FieldProps<string, StoreItemFormType>) => (
					<div className={styles.storeItemForm__item}>
						<label className={styles.storeItemForm__item_label} htmlFor="productUrl">
							Link to Website<span className={styles.storeItemForm__item_required}>*</span>
						</label>
						<InputText
							id="productUrl"
							field={field}
							placeholder="https://"
							setFieldValue={form.setFieldValue}
							parentClassName={styles.dialog__form_item__textfield}
						/>
						{meta.error && meta.touched && <InputErrorHint errorText={meta.error} disableMargin={true} />}
					</div>
				)}
			</Field>

			<div className={styles.storeItemForm__actions}>
				<ButtonDefault
					classList={['secondary', 'lg']}
					parentClassName={styles.storeItemForm__actions_button}
					title="Cancel"
					clickHandler={handleCancel}
				/>
				<div className={styles.storeItemForm__actions_button}>
					{isSaving ? (
						<Loader
							loadProps={{
								disableCenterStyle: true,
								parentClassName: styles.storeItemForm__actions_button_loader,
							}}
						/>
					) : (
						<ButtonDefault
							submitType={true}
							classList={['primary', 'lg']}
							parentClassName={cx(styles.storeItemForm__actions_button, {
								[styles.disable_submit]: !dirty || !isValid,
							})}
							title={isNew ? 'Create' : 'Save'}
							disabled={!dirty || !isValid}
						/>
					)}
				</div>
			</div>
		</Form>
	);
};

export default StoreItemForm;
