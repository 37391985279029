import { FC } from 'react';
import cx from 'classnames';
import { StoreItemSort, SortDirection, STORE_ITEM_POSSIBLE_SORT } from '@joc/api-gateway';

import { ReactComponent as ArrowSortSVG } from 'assets/image/icons/arrow-sort.svg';

import styles from '../StoreTable.module.scss';

type SortableHeaderItemProps = {
	sortBy: STORE_ITEM_POSSIBLE_SORT;
	title: string;
	// eslint-disable-next-line no-unused-vars
	handleSortChange: (sortBy: STORE_ITEM_POSSIBLE_SORT) => void;
	sort?: StoreItemSort;
};

const SortableHeaderItem: FC<SortableHeaderItemProps> = ({ sortBy, title, handleSortChange, sort }) => {
	return (
		<div className={styles.storeTable_thead_withSort} onClick={() => handleSortChange(sortBy)}>
			<span>{title}</span>
			<ArrowSortSVG
				className={
					(sort?.sortBy === sortBy &&
						cx({
							[styles.storeTable_thead_withSort_ASC]: sort?.sortDir === SortDirection.ASC,
							[styles.storeTable_thead_withSort_DESC]: sort?.sortDir === SortDirection.DESC,
						})) ||
					''
				}
			/>
		</div>
	);
};

export default SortableHeaderItem;
