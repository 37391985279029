import React, { FC, useCallback } from 'react';
import cx from 'classnames';
import styles from './CellDefault.module.scss';

type CellDefaultProps = {
	text: string | number;
	parentClassName: string;
	boldDefaultOrg?: boolean;
};

const CellDefault: FC<CellDefaultProps> = ({ text, parentClassName, boldDefaultOrg }: CellDefaultProps) => {
	const Content = useCallback(() => {
		if (boldDefaultOrg) {
			const [defaultOrg, ...orgs] = (text as string).split(', ');
			return (
				<span>
					<b>{defaultOrg}</b>
					{orgs.length ? ', ' : ''} {orgs.join(', ')}
				</span>
			);
		}

		return <span>{text}</span>;
	}, [text, boldDefaultOrg]);
	return (
		<div className={cx(styles.main, styles[parentClassName])} title={text as string}>
			<Content />
		</div>
	);
};

export default CellDefault;
