import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from '../../../core/API';
import { STORE_ITEMS_QUERY_KEY } from '../../../core/constants';

interface IUseDeleteStoreItem {
	// eslint-disable-next-line no-unused-vars
	deleteStoreItem: (id: number) => void;
}

const deleteStoreItemApi = async (id: number) => {
	return API.deleteStoreItem(id);
};

export const useDeleteStoreItem = (): IUseDeleteStoreItem => {
	const queryClient = useQueryClient();
	const { mutate: deleteStoreItem } = useMutation<void, Error, number>({
		mutationFn: storeItem => deleteStoreItemApi(storeItem),
		onSettled: () => queryClient.invalidateQueries({ queryKey: [STORE_ITEMS_QUERY_KEY] }),
	});

	return { deleteStoreItem };
};
