import { GroupsRequest, IGroupsRequest } from '@joc/api-gateway';
import { API } from 'core/API';
import { Dispatch } from 'redux';
import { CREATE_SMART_GROUP, DELETE_SMART_GROUP, GET_SMART_GROUPS_ALL, SET_ERROR } from 'redux/actions-types';

export const getSmartGroupsAll =
	(orgId: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			const response = await API.getAllGroups(orgId.toString());
			dispatch({ type: GET_SMART_GROUPS_ALL, payload: response });
		} catch (error: any) {
			dispatch({ type: SET_ERROR, payload: { state: true, message: error?.response?.message || error.message } });
		}
	};

export const createSmartGroup = (body: IGroupsRequest) => async (dispatch: Dispatch) => {
	try {
		const response = await API.createGroup(undefined, GroupsRequest.fromJS(body));
		const groupResponse = await API.getGroup(response.id, body.organizationId?.toString());
		dispatch({ type: CREATE_SMART_GROUP, payload: groupResponse });
	} catch (error: any) {
		dispatch({
			type: SET_ERROR,
			payload: { state: true, message: error?.response?.message || error?.response?.message || error.message },
		});
	}
};

export const deleteSmartGroup = (id: number | undefined, orgId: number) => async (dispatch: Dispatch) => {
	try {
		if (!id)
			return dispatch({ type: SET_ERROR, payload: { state: true, message: 'Sorry, we can`t find group id' } });
		await API.deleteGroup(id, orgId.toString());
		dispatch({ type: DELETE_SMART_GROUP, payload: id });
	} catch (error: any) {
		dispatch({
			type: SET_ERROR,
			payload: { state: true, message: error?.response?.message || error?.response?.message || error.message },
		});
	}
};
